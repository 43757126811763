<template>
  <div id="app">
    <NavbarComponent />
    <SidebarComponent />
    <div class="content-wrapper">
            <router-view></router-view>
            </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';
import SidebarComponent from './components/SidebarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
  },
};
</script>

<style>
/* Add any global styles here */
</style>
