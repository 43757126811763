<template>
    <div id="main-layout">
      <HeaderComponent />
      <NavbarComponent />
        <router-view></router-view>
      <FooterComponent />
    </div>
  </template>
  
  <script>
  import NavbarComponent from './NavbarComponent.vue';
  import FooterComponent from './FooterComponent.vue';
  import HeaderComponent from './HeaderComponent.vue';
  
  export default {
    name: 'MainLayout',
    components: {
        NavbarComponent,
        FooterComponent,
        HeaderComponent
    }
  };
  </script>
  
  <style>
  #main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  #content {
    flex: 1;
    padding: 20px;
  }
  </style>