import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
//import '@fontawesome/fontawesome-free/css/all.min.css'; 
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'admin-lte/dist/js/adminlte.min.js';

import router from './router';

const app = createApp(App);
app.use(router);
app.mount('#app');