// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePageComponent from '@/components/HomePageComponent.vue';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import MainLayout from '@/components/MainLayout.vue';
import AboutPageComponent from '@/components/AboutPageComponent.vue';

const routes = [
    {
      path: '/',
      component: MainLayout,
      children: [
        {
          path: '',
          name: 'Home',
          component: HomePageComponent
        },
        {
          path: 'about',
          name: 'About',
          component: AboutPageComponent
        }
      ]
    }
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes
  });

router.beforeEach((to, from, next) => {
    console.log('Navigation guard triggered');
    const token = Cookies.get('access_token');

    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime) {
                // Token is expired, redirect to login
                window.location.href = 'http://auth.vendorwarden.com';
            } else {
                // Token is valid, proceed to route
                next();
            }
        } catch (error) {
            console.error(error);
            // Error decoding token, redirect to login
            window.location.href = 'http://auth.vendorwarden.com/?2';        
        }
    } else {
        // No token, redirect to login
        window.location.href = 'http://auth.vendorwarden.com/?3';
    }
});

export default router;
